import { initializeBugsnag } from '@cfacorp/ctrl-platform-ui-core-utils';
import { lazy, Suspense } from 'react';
import './i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import packageJson from '../package.json';
import 'cfa-react-components/dist/styles.min.css';
import './index.css';

const AppRouter = lazy(() => import('./routes/AppRouter'));
const queryClient = new QueryClient();

initializeBugsnag(packageJson.version);

export default function Root() {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={null}>
        <AppRouter />
      </Suspense>
    </QueryClientProvider>
  );
}
