import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import Restaurant from '../icons/Restaurant.svg';
import { displayMapMarker } from '../containers/DeliveryArea/utils';
import { useStoreCoordinates } from './useStoreCoordinates';

export const useMap = () => {
  const storeCoordinates = useStoreCoordinates();
  const { latitude = 0, longitude = 0 } = storeCoordinates ?? {};
  const mapRef = useRef<HTMLDivElement>(null);

  const [addressMarker, setAddressMarker] = useState<google.maps.Marker>();
  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    setMap(
      new google.maps.Map(mapRef.current as HTMLElement, {
        center: new google.maps.LatLng(latitude, longitude),
        streetViewControl: false,
        keyboardShortcuts: false,
        zoom: 11.5,
      }),
    );
  }, [latitude, longitude]);

  useEffect(() => {
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      icon: Restaurant,
    });
    marker.setMap(map);
  }, [latitude, longitude, map]);

  const bounds = useMemo(() => map?.getBounds(), [map]);

  const handleShowOnMap = (placeId: string) => {
    if (map) {
      displayMapMarker(placeId, map, (marker: google.maps.Marker) => {
        // Remove current marker if one exists
        if (addressMarker) {
          addressMarker.setMap(null);
        }
        marker.setMap(map);
        setAddressMarker(marker);
      });
    } else {
      console.error('Can not plot marker for the placeId provided', placeId);
    }
  };

  const clearSelectedAddress = useCallback(() => {
    addressMarker?.setMap(null);
    setAddressMarker(undefined);
  }, [addressMarker]);

  const mapContent = <StyledMap id="map" ref={mapRef} />;

  return {
    clearSelectedAddress,
    handleShowOnMap,
    addressMarker,
    mapContent,
    bounds,
    map,
  };
};

const StyledMap = styled.div`
  a[href^="http://maps.google.com/maps"],
  a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }
  .gmnoprint span,
  .gmnoprint a,
  .gm-style-cc {
    display: none;
  }
  height: 100%;
  width: 100%;
`;
