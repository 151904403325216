import { z } from 'zod';
import AppConstants from '../AppConstants';
import CateringSettings, {
  cateringSettingsSchema,
} from '../types/responses/CateringSettings';
import {
  CheckInInstructionsDefaults,
  checkInInstructionsDefaultsSchema,
} from '../types/responses/CheckInInstructionsDefaults';
import DopLocation, { dopLocationSchema } from '../types/responses/DopLocation';
import LimitedCatering, {
  limitedCateringHoursResponseSchema,
} from '../types/responses/LimitedCatering';
import Localization, {
  localizationResponseSchema,
} from '../types/responses/Localization';
import {
  PersonalOrderSettings,
  personalOrderSettingsSchema,
} from '../types/responses/PersonalOrderSettings';
import PhoneProvider, {
  phoneProviderSchema,
} from '../types/responses/PhoneProvider';
import ScheduledClosure, {
  scheduledClosureSchema,
} from '../types/responses/ScheduledClosure';
import StoreDetails, {
  storeDetailsResponseSchema,
} from '../types/responses/StoreDetails';
import StoreHolidayHours, {
  storeHolidayHoursSchema,
} from '../types/responses/StoreHolidayHours';
import StoreHours, {
  storeHoursResponseSchema,
} from '../types/responses/StoreHours';
import env from '../env';
import CaresTiers, {
  caresTiersResponseSchema,
} from '../types/responses/CaresTiers';
import CaresConfig, { caresConfigSchema } from '../types/responses/CaresConfig';
import Videos, { videosSchema } from '../types/responses/Videos';
import {
  ThirdPartyDeliveryStatus,
  thirdPartyDeliveryStatusSchema,
} from '../types/responses/ThirdPartyDeliveryStatus';
import HoursObject from '../types/responses/HoursObject';
import createApiClient from './createApiClient';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.REACT_APP_ENVIRONMENT.DEV]:
    'https://digitalopsapi.dev-spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TEST]:
    'https://digitalopsapi.test-spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TRAIN]:
    'https://digitalopsapi.test-spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.BETA]:
    'https://digitalopsapi.spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.PROD]:
    'https://digitalopsapi.spc.cfahome.com',
};

const baseUrl = baseUrlMap[env.REACT_APP_ENVIRONMENT];

const apiClient = createApiClient(baseUrl);

const dopService = {
  getCaresTiers: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/caresTiers`, {
        validationSchema: caresTiersResponseSchema,
      })
      .json<CaresTiers>(),
  getCaresConfig: () =>
    apiClient
      .get('app/caresConfig', {
        validationSchema: caresConfigSchema,
      })
      .json<CaresConfig>(),
  addCaresTiersToCart: caresTiers =>
    apiClient
      .post(`store/${caresTiers.locationNumber}/caresTiers`, {
        json: caresTiers,
      })
      .json(),
  publishCaresTiers: caresTiers =>
    apiClient
      .post(`store/${caresTiers.locationNumber}/caresTiers?autoPublish=true`, {
        json: caresTiers,
      })
      .json(),
  getPersonalOrderSettings: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/personalOrderSettings`, {
        validationSchema: personalOrderSettingsSchema,
      })
      .json<PersonalOrderSettings>(),
  addPersonalOrderSettingsToCart: (
    storeNumber: string,
    personalOrderSettings: PersonalOrderSettings,
  ) =>
    apiClient
      .post(`store/${storeNumber}/personalOrderSettings`, {
        json: personalOrderSettings,
      })
      .json(),
  publishPersonalOrderSettings: (
    storeNumber: string,
    personalOrderSettings: PersonalOrderSettings,
  ) =>
    apiClient
      .post(`store/${storeNumber}/personalOrderSettings?autoPublish=True`, {
        json: personalOrderSettings,
      })
      .json(),
  getCheckInInstructionsDefaults: () =>
    apiClient
      .get(`app/mobileCheckInDefaults`, {
        validationSchema: z.array(checkInInstructionsDefaultsSchema),
      })
      .json<CheckInInstructionsDefaults[]>(),
  getStoreClosures: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/storeClosures`, {
        validationSchema: z.array(scheduledClosureSchema),
      })
      .json<ScheduledClosure[]>(),
  addStoreClosuresToCart: (
    storeNumber: string,
    updatedStoreClosures: ScheduledClosure[],
  ) =>
    apiClient
      .post(`store/${storeNumber}/storeClosures`, {
        json: updatedStoreClosures,
      })
      .json(),
  publishStoreClosures: (
    storeNumber: string,
    updatedStoreClosures: ScheduledClosure[],
  ) =>
    apiClient
      .post(`store/${storeNumber}/storeClosures?autoPublish=True`, {
        json: updatedStoreClosures,
      })
      .json(),
  getLimitedCatering: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/specialEvents`, {
        validationSchema: z.array(limitedCateringHoursResponseSchema),
      })
      .json<LimitedCatering[]>(),
  addLimitedCateringToCart: (
    storeNumber: string,
    updatedLimitedCatering: LimitedCatering[],
  ) =>
    apiClient
      .post(`store/${storeNumber}/specialEvents`, {
        json: updatedLimitedCatering,
      })
      .json(),
  publishLimitedCatering: (
    storeNumber: string,
    updatedLimitedCatering: LimitedCatering[],
  ) =>
    apiClient
      .post(`store/${storeNumber}/specialEvents?autoPublish=True`, {
        json: updatedLimitedCatering,
      })
      .json(),
  getDopLocation: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/location`, {
        validationSchema: dopLocationSchema,
      })
      .json<DopLocation>(),
  getDopPublishedLocation: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/location:published`, {
        validationSchema: dopLocationSchema,
      })
      .json<DopLocation>(),
  addDopLocationToCart: (storeNumber: string, locationData: DopLocation) =>
    apiClient
      .post(`store/${storeNumber}/location`, { json: locationData })
      .json(),
  publishDopLocation: (storeNumber: string, locationData: DopLocation) =>
    apiClient
      .post(`store/${storeNumber}/location?autoPublish=True`, {
        json: locationData,
      })
      .json(),
  getCateringSettings: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/cateringOrderSettings`, {
        validationSchema: cateringSettingsSchema,
      })
      .json<CateringSettings>(),
  getLocalization: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/localization`, {
        validationSchema: localizationResponseSchema,
      })
      .json<Localization>(),
  addLocalizationToCart: (storeNumber: string, localization: Localization) =>
    apiClient
      .post(`store/${storeNumber}/localization`, {
        json: localization,
      })
      .json(),
  publishLocalization: (storeNumber: string, localization: Localization) =>
    apiClient
      .post(`store/${storeNumber}/localization?autoPublish=True`, {
        json: localization,
      })
      .json(),
  getPhoneProviders: () =>
    apiClient
      .get(`app/phoneproviders`, {
        validationSchema: z.array(phoneProviderSchema),
      })
      .json<PhoneProvider[]>(),
  getStoreHours: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/week`, {
        validationSchema: storeHoursResponseSchema,
      })
      .json<StoreHours>(),
  addStoreHoursToCart: (storeNumber: string, updatedStoreHours: StoreHours) =>
    apiClient
      .post(`store/${storeNumber}/week`, { json: updatedStoreHours })
      .json(),
  publishStoreHours: (storeNumber: string, updatedStoreHours: StoreHours) =>
    apiClient
      .post(`store/${storeNumber}/week?autoPublish=True`, {
        json: updatedStoreHours,
      })
      .json(),
  getStoreDetails: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/storeSettings`, {
        validationSchema: storeDetailsResponseSchema,
      })
      .json<StoreDetails>(),
  getStoreHolidayHours: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/holidays`, {
        validationSchema: z.array(storeHolidayHoursSchema),
      })
      .json<StoreHolidayHours[]>(),
  getDopCommunityVideos: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/video`, {
        validationSchema: z.array(videosSchema),
      })
      .json<Videos[]>(),
  addStoreHolidayHoursToCart: (
    storeNumber: string,
    updatedStoreHolidayHours: StoreHolidayHours[],
  ) =>
    apiClient
      .post(`store/${storeNumber}/holidays`, {
        json: updatedStoreHolidayHours,
      })
      .json(),
  addSelectedStoreHolidayHoursToCart: (
    storeNumber: string,
    selectedHolidayID: string,
    hoursData: HoursObject,
  ) =>
    apiClient
      .post(
        `store/${storeNumber}/restaurantHours?holidayId=${selectedHolidayID}`,
        {
          json: hoursData,
        },
      )
      .json(),
  publishStoreHolidayHours: (
    storeNumber: string,
    updatedStoreHolidayHours: StoreHolidayHours[],
  ) =>
    apiClient
      .post(`store/${storeNumber}/holidays?autoPublish=True`, {
        json: updatedStoreHolidayHours,
      })
      .json(),
  publishSelectedStoreHolidayHours: (
    storeNumber: string,
    selectedHolidayID: string,
    hoursData: HoursObject,
  ) =>
    apiClient
      .post(
        `store/${storeNumber}/restaurantHours?holidayId=${selectedHolidayID}?autoPublish=True`,
        {
          json: hoursData,
        },
      )
      .json(),
  addStoreDetailsToCart: (
    storeNumber: string,
    updatedStoreDetails: StoreDetails,
  ) =>
    apiClient
      .post(`store/${storeNumber}/storeSettings`, {
        json: updatedStoreDetails,
      })
      .json(),
  publishStoreDetails: (
    storeNumber: string,
    updatedStoreDetails: StoreDetails,
  ) =>
    apiClient
      .post(`store/${storeNumber}/storeSettings?autoPublish=True`, {
        json: updatedStoreDetails,
      })
      .json(),
  addCateringSettingsToCart: (
    storeNumber: string,
    cateringSettings: CateringSettings,
  ) =>
    apiClient
      .post(`store/${storeNumber}/cateringOrderSettings`, {
        json: cateringSettings,
      })
      .json(),
  publishCateringSettings: (
    storeNumber: string,
    cateringSettings: CateringSettings,
  ) =>
    apiClient
      .post(`store/${storeNumber}/cateringOrderSettings?autoPublish=True`, {
        json: cateringSettings,
      })
      .json(),
  getThirdPartyDeliveryStatus: (storeNumber: string) =>
    apiClient
      .get(`store/${storeNumber}/3pistatus`, {
        validationSchema: thirdPartyDeliveryStatusSchema,
      })
      .json<ThirdPartyDeliveryStatus>(),
  revertChanges: (storeNumber: string, dataPoints: string[]) =>
    apiClient.post(`store/${storeNumber}/revert`, { json: dataPoints }).json(),
  publishChanges: (storeNumber: string, dataPoints: string[]) =>
    apiClient.post(`store/${storeNumber}/publish`, { json: dataPoints }).json(),
} as const;

export default dopService;
