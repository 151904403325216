import { createConnectTransport } from '@bufbuild/connect-web';
import { createPromiseClient, Interceptor } from '@bufbuild/connect';
import { DeliveryAreaService } from '@bufteam/cfacorp_delivery.bufbuild_connect-es/cfa/delivery/area/v1/area_service_connect';
import {
  GetDeliveryAreasRequest,
  GetDeliveryAreasResponse,
  UpdateDeliveryAreasRequest,
  UpdateDeliveryAreasResponse,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import env from '../env';

interface AccessToken {
  accessToken: string;
}
interface OktaTokenStorage {
  accessToken: AccessToken;
}

const DEV_URL = 'https://delivery.api.dev.crndev.chick-fil-a.com';
const QA_URL = 'https://delivery.api.qa.crndev.chick-fil-a.com';
const BETA_PROD_URL = 'https://delivery.api.my.chick-fil-a.com';
const ENVIRONMENTS = {
  TRAIN: 'train',
  TEST: 'test',
  BETA: 'beta',
  PROD: 'prod',
  DEV: 'dev',
};
const baseUrlMap: { [key: string]: string } = {
  [ENVIRONMENTS.BETA]: BETA_PROD_URL,
  [ENVIRONMENTS.PROD]: BETA_PROD_URL,
  [ENVIRONMENTS.TRAIN]: QA_URL,
  [ENVIRONMENTS.TEST]: QA_URL,
  [ENVIRONMENTS.DEV]: DEV_URL,
};
const baseUrl = baseUrlMap[env.REACT_APP_ENVIRONMENT];

const headerInterceptor: Interceptor = next => async req => {
  const tokenInfo: OktaTokenStorage = JSON.parse(
    localStorage.getItem('okta-token-storage') ?? '',
  );
  req.header.set(
    'Authorization',
    `JWTBearer ${tokenInfo.accessToken.accessToken}`,
  );
  return await next(req);
};
const transport = createConnectTransport({
  baseUrl,
  interceptors: [headerInterceptor],
});
const client = createPromiseClient(DeliveryAreaService, transport);

export const deliveryAreaService: {
  updateDeliveryAreas: (
    r: UpdateDeliveryAreasRequest,
  ) => Promise<UpdateDeliveryAreasResponse>;
  getDeliveryAreas: (
    r: GetDeliveryAreasRequest,
  ) => Promise<GetDeliveryAreasResponse>;
} = {
  getDeliveryAreas: (r: GetDeliveryAreasRequest) => client.getDeliveryAreas(r),
  updateDeliveryAreas: (r: UpdateDeliveryAreasRequest) =>
    client.updateDeliveryAreas(r),
};
