import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { polygon } from '@turf/turf';

export const getCoordinates = (
  r: PromiseSettledResult<Coordinates[] | undefined>,
) => (r.status === 'fulfilled' ? r.value ?? [] : []);
export const getTurfPolygon = co =>
  polygon([ensureClosedPolygon(co).map(c => [c.longitude, c.latitude])]);
const ensureClosedPolygon = co =>
  co.length > 0 &&
  (co[0]?.latitude !== co[co.length - 1]?.latitude ||
    co[0]?.longitude !== co[co.length - 1]?.longitude)
    ? [...co, { ...co[0] }]
    : co;
