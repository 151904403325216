import { useState, createContext, useMemo, useEffect } from 'react';
import { useSelectedStoreInfo } from '@cfacorp/ctrl-platform-shared-react-components';
import { VenueNameContextType, Venue } from '../types/VenueNameContextType';

type component = {
  children: React.ReactNode;
};

export const venueNameContext = createContext<VenueNameContextType>({
  setvenueSelection: () => '',
  venueSelection: {
    venue: { venue_id: '', venue_name: '' },
  },
});

const VenueNameContextProvider = ({ children }: component) => {
  const selectedStoreInfo = useSelectedStoreInfo();
  const [venueSelection, setvenueSelection] = useState<Venue>({
    venue: {
      venue_id: selectedStoreInfo?.storeNumber + ':V001',
      venue_name: '',
    },
  });
  const venueSelectionMemo = useMemo(
    () => ({ venueSelection, setvenueSelection }),
    [venueSelection],
  );
  useEffect(() => {
    // set default store venue
    setvenueSelection({
      venue: {
        venue_id: selectedStoreInfo?.storeNumber + ':V001',
        venue_name: selectedStoreInfo?.storeName || '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreInfo?.storeNumber]);
  return (
    <div>
      <venueNameContext.Provider value={venueSelectionMemo}>
        {children}
      </venueNameContext.Provider>
    </div>
  );
};

export default VenueNameContextProvider;
