import {
  AreaType,
  GetDeliveryAreasRequest,
} from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/area/v1/area_service_pb';
import { useCallback } from 'react';
import { deliveryAreaService } from '../services';
import { useTravelTypeContext } from '../context/TravelTypeContext';
import { useTimeEstimateContext } from '../context/TimeEstimateContext';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useGetAreaQueries = () => {
  const locationNumber = useGetStoreNumber();
  const { travelType } = useTravelTypeContext();
  const { timeEstimate } = useTimeEstimateContext();

  const queryCurrent = useCallback(async () => {
    if (!locationNumber) {
      return;
    }
    const { deliveryAreas } = await deliveryAreaService.getDeliveryAreas(
      new GetDeliveryAreasRequest({
        travelType: travelType,
        areaType: AreaType.CURRENT,
        distanceMinutes: timeEstimate,
        locationNumber,
      }),
    );
    if (deliveryAreas) {
      return (
        deliveryAreas?.areas?.find(a => a.areaType === AreaType.CURRENT)
          ?.coordinates ?? []
      );
    }
  }, [locationNumber, timeEstimate, travelType]);

  const queryDraft = useCallback(async () => {
    if (!locationNumber) {
      return;
    }
    const { deliveryAreas } = await deliveryAreaService.getDeliveryAreas(
      new GetDeliveryAreasRequest({
        travelType: travelType,
        areaType: AreaType.DRAFT,
        locationNumber,
      }),
    );
    if (deliveryAreas) {
      return (
        deliveryAreas?.areas?.find(a => a.areaType === AreaType.DRAFT)
          ?.coordinates ?? []
      );
    }
  }, [locationNumber, travelType]);

  const queryMax = async () => {
    if (!locationNumber) {
      return;
    }
    const { deliveryAreas } = await deliveryAreaService.getDeliveryAreas(
      new GetDeliveryAreasRequest({
        travelType: travelType,
        areaType: AreaType.MAX,
        locationNumber,
      }),
    );
    if (deliveryAreas) {
      return (
        deliveryAreas?.areas?.find(a => a.areaType === AreaType.MAX)
          ?.coordinates ?? []
      );
    }
  };

  return { queryCurrent, queryDraft, queryMax };
};
