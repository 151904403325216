enum RouteConstants {
  HOME = '/',
  MENU_BASE_NAME = '/menu',
  POS_BASE_NAME = '/pos',
  EQUIPMENT_FAILURE = '/equipmentFailure',
  LIMITED_MENUS = '/limitedMenus',
  INGREDIENT_BASED_LOCKS = '/ingredientBasedLocks',
  MASTER_MENU = '/masterMenu',
  ITEM_LOCK = '/itemLock',
  PRICE_OVERRIDE = '/priceOverride',
  AUTO_SELL_CONDIMENTS = '/autoSellCondiments',
  CONDIMENT_LOOKUP = '/autoSellCondiments/condiment-lookup',
  VENUES = '/venues',
  VENUES_DETAIL = '/detail/:id',
  CUSTOM_ITEM_NAMES = '/customItemNames',
  CUSTOM_RECEIPT_TEXT = '/customReceiptText',
  LOCAL_ITEMS = '/localItems',
  LOCAL_ITEMS_DETAILS = '/localItems/edit/:menuFrameId/:menuFrameButtonId',
  TAX_EXEMPT = '/taxExemptAccounts',
}

export default RouteConstants;
