/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  AppRoutes: {
    baseName: 'ViewPoint Home',
    createProfile: 'Create profile',
    mainMenu: 'Main Menu',
  },
  Bump: {
    bumpsHeaderBeginning: 'Choose ',
    bumpsHeaderBold: 'at least one ',
    bumpsHeaderEnd:
      'way to remove orders on VP screens that will have this profile enabled.',
    choice1: 'Remove order when on ',
    choice2: 'Remove order when it is bagged',
    choice3: 'Remove order when it is completed',
    choice4: 'Remove order when it is cancelled',
    choice5: 'Remove order when it is removed',
    viewMoreProfilesButton: 'View more profiles',
  },
  CreateProfile: {
    bump: 'Bump Component',
    continue: 'Continue',
    createProfileLabel: 'Create Profile',
    destinations: 'Destinations Component',
    layout: 'Layout Component',
    menuItems: 'Menu Items Compoent',
    review: 'Review Compoent',
    role: 'Role Component',
    stepperSubtitle: 'Complete the following steps to create a new profile',
  },
  DeleteProfileLabels: {
    CancelButtonLabel: 'Cancel',
    DeleteButtonLabel: 'Delete',
    Header: 'Delete Profile',
    Subtitle:
      'When a profile is deleted, you are unable to make edits. Are you sure you want to delete this profile',
  },
  DestinationLabel: {
    fulfilmentHeaderBeginning: 'Choose ',
    fulfilmentHeaderBold: 'at least one order fulfilment ',
    fulfilmentHeaderEnd: 'option to route to this profile',
    originHeaderBeginning: 'Choose ',
    originHeaderBold: 'at least one origin ',
    originHeaderEnd: 'to route to this profile',
  },
  EditProfile: {
    Bumps: 'Bumps',
    EditButtonLabel: 'Edit',
    Label: 'Edit Profile',
    Menu: 'Menu Items',
    OrderFulfil: 'Order Origin & Fulfilment',
    PermanentlyDeleteButtonLabel: 'Permanently Delete',
    RenameButtonLabel: 'Rename',
    Role: 'Role',
    Subtitle: 'Edit configurations to customize VP screens for this profile',
  },
  EditRoleModal: {
    cancel: 'Cancel',
    changeRoleButton: 'Change Role',
    editModalDescription:
      'Are you sure you want to change the role for this profile? This will change the menu items that are currently selected for this profile, Drinks - Test.',
    editModalHeader: 'Change Role',
  },
  ErrorMessage: {
    BumpsProfilesOrStatusesHeader: 'No Bumps Profiles or Statuses',
    BumpsProfilesOrStatusesSubtitle:
      'There are currently no bump profiles or statuses options selected. To continue, you must choose at least one from category.',
    MenuItemsHeader: 'No Menu Items Selected',
    MenuItemsSubtitle:
      'There are currently no selections in the menu items. To continue, you must choose at least one.',
    OrderOriginAndFulfillmentSubtitle:
      'There are currently no order origins or fulfillment options selected. To continue, you must choose at least one from category.',
    OrderOriginOrFulfillmentHeader: 'No Origin Order or Fulfillment',
    OrderOriginOrFulfillmentSubtitle:
      'There are currently no selections in either the origin or fulfillment category. To continue, you must choose at least one from category.',
  },
  Home: {
    Subtitle:
      'Manage profiles and use pre-defined templates to customize store experiences',
    title: 'VP Profiles',
  },
  Layout: {
    customLabel: 'Custom View',
    gridLabel: 'Grid View',
    headerLabel:
      'Choose a UI layout to customize the appearance of VP screen(s)',
  },
  MenuItems: {
    allItemsModalCloseButton: 'Close',
    allMenuItemsModalTitle: 'All Menu Items',
    cancelButton: 'Cancel',
    copyResultClipboard: 'Copy results to clipbord',
    editButton: 'Edit',
    editedText: 'Edited',
    editItemButton: 'Edit items',

    itemsText: 'items',
    menuItemNameText: 'Edit Menu Items for',
    menuTitle:
      'Current selections are based on the profile’s assigned role. Make customizations to all groups and menu items',
    viewAllButton: 'View all menu items',
  },
  PreviewButton: {
    preview: 'Preview',
  },
  ProfileNameInput: {
    cancelButton: 'Cancel',
    createConfirmLabel: 'Continue',
    createHeader: 'Create profile',
    createLabel: 'Name',
    createPlaceholder: 'Enter a name',
    createSubtitle:
      'First, choose a name for the new profile. You can change this name later.',
    editConfirmLabel: 'Continue',
    editHeader: 'Rename profile',
    editLabel: 'Name',
    editPlaceholder: '',
    editSubtitle: '',
    renameButton: 'Rename',
  },
  Review: {
    bumpsLabel: 'Bumps',
    destinationsLabel: 'Origin & Fulfilment',
    layoutLabel: 'Layout',
    menuItemsLabel: 'Menu Items',
    roleLabel: 'Role',
  },
  RoleLabel: {
    roleHeaderBeginning: 'Choose ',
    roleHeaderBold: 'one ',
    roleHeaderEnd: 'role for your profile',
  },
  Stepper: {
    backButton: 'Back',
    bumpsLabel: 'Bumps',
    destinationsLabel: 'Origin & Fulfilment',
    layoutLabel: 'Layout',
    menuitemsLabel: 'Menu Items',
    nextButton: 'Next',
    reviewLabel: 'Review',
    rolelabel: 'Role',
    submitButton: 'Submit',
  },
  Venues: {
    activeVenuesLabel: 'Active Venues',
    addVenueButton: 'Add a Venue',
    archivedVenuesLabel: 'Archived Venues',
    createModalCancelButton: 'Cancel',
    createModalSubmitButton: 'Create Venue',
    detailsActivateButton: 'Activate this Venue',
    detailsArchiveButton: 'Archive this Venue',
    detailsDuplicateButton: 'Duplicate this Venue',
    detailsSaveButton: 'Save',
    emptyActiveVenuesLabel: 'No active venues',
    emptyArchivedVenuesLabel: 'No archived venues',
    idLabel: '({{storeNumber}}:{{venueId}})',
    popoverMenuActivateButton: 'Activate',
    popoverMenuArchiveButton: 'Archive',
    popoverMenuDeleteButton: 'Permanently Delete',
    popoverMenuDuplicateButton: 'Duplicate',
    popoverMenuEditButton: 'View/Edit Venue',
    popoverMenuResendButton: 'Resend Venue Data',
    promptModalCancelButton: 'Cancel',
    venueChangeButton: 'Change',
    venueChangeLabel: 'Venue: {{name}}',
    venueDetailActiveLabel: 'Status: Active',
    venueDetailArchivedLabel: 'Status: Archived',
    venueModalCancelButton: 'Cancel',
    venueModalHeader: 'Select a venue',
    venueModalSelectLabel: 'Venue',
    venueModalSubmitButton: 'Select',
  },
};
