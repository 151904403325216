import { useState, createContext, useMemo, useContext, useEffect } from 'react';
import { VpProfileContextType, VpData } from '../types/MultiStepContextType';

import { VenueNameContextType } from '../types/VenueNameContextType';
import { venueNameContext } from './VenueNameContextProvider';

type component = {
  children: React.ReactNode;
};

export const multiStepContext = createContext<VpProfileContextType>({
  vpProfileData: {
    profileName: {
      profileName: '',
    },
    role: {
      role: '',
    },
    fulfillments: {
      fulfillment: [],
    },
    origins: {
      origin: [],
    },
    thirdParty: {
      originThirdParty: [],
    },
    bumpProfiles: {
      bumpProfiles: [],
    },
    bumpStatuses: {
      bumpStatuses: [],
    },
    bumpOwnProfile: {
      bumpOwnProfile: true,
    },
    vpProfileId: {
      vpProfileId: '',
    },
    fullSelectedDeviceGroups: {
      fullSelectedDeviceGroups: [],
    },
    partialSelectedDeviceGroups: {
      partialSelectedDeviceGroups: [],
    },
    partialSelectedItems: {},
    editedDeviceGroups: {
      editedDeviceGroups: [],
    },
  },

  setVPProfileData: () => '',
});

const MultiStepContextProvider = ({ children }: component) => {
  const { venueSelection } = useContext(
    venueNameContext,
  ) as VenueNameContextType;

  const [vpProfileData, setVPProfileData] = useState<VpData>({
    profileName: {
      profileName: '',
    },
    role: {
      role: '',
    },
    fulfillments: {
      fulfillment: [],
    },
    origins: {
      origin: [],
    },
    thirdParty: {
      originThirdParty: [],
    },
    bumpProfiles: {
      bumpProfiles: [],
    },
    bumpStatuses: {
      bumpStatuses: [],
    },
    bumpOwnProfile: {
      bumpOwnProfile: true,
    },
    vpProfileId: {
      vpProfileId: '',
    },
    fullSelectedDeviceGroups: {
      fullSelectedDeviceGroups: [],
    },
    partialSelectedDeviceGroups: {
      partialSelectedDeviceGroups: [],
    },
    partialSelectedItems: {},
    editedDeviceGroups: {
      editedDeviceGroups: [],
    },
  });

  useEffect(() => {
    setVPProfileData({
      profileName: {
        profileName: '',
      },
      role: {
        role: '',
      },
      fulfillments: {
        fulfillment: [],
      },
      origins: {
        origin: [],
      },
      thirdParty: {
        originThirdParty: [],
      },
      bumpProfiles: {
        bumpProfiles: [],
      },
      bumpStatuses: {
        bumpStatuses: [],
      },
      bumpOwnProfile: {
        bumpOwnProfile: true,
      },
      vpProfileId: {
        vpProfileId: '',
      },
      fullSelectedDeviceGroups: {
        fullSelectedDeviceGroups: [],
      },
      partialSelectedDeviceGroups: {
        partialSelectedDeviceGroups: [],
      },
      partialSelectedItems: {},
      editedDeviceGroups: {
        editedDeviceGroups: [],
      },
    });
  }, [venueSelection]);

  const multiStepContextMemo = useMemo(
    () => ({ vpProfileData, setVPProfileData }),
    [vpProfileData],
  );
  return (
    <div>
      <multiStepContext.Provider value={multiStepContextMemo}>
        {children}
      </multiStepContext.Provider>
    </div>
  );
};

export default MultiStepContextProvider;
