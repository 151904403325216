import ky from 'ky';
import { getAccessToken } from '@cfacorp/ctrl-platform-ui-core-utils';
import logErrorToBugsnag from '../utils/logErrorToBugsnag';

const createApiClient = (baseUrl: string) => {
  return ky.create({
    prefixUrl: baseUrl,
    retry: {
      limit: 2,
      statusCodes: [401, 403, 504, 500],
    },
    hooks: {
      beforeRequest: [
        request => {
          request.headers.set('Authorization', `Bearer ${getAccessToken()}`);
          request.headers.set('Return-Route', window.location.pathname);
        },
      ],
      afterResponse: [
        (request, options, response) => {
          if (options?.validationSchema) {
            const validationResults =
              options.validationSchema?.safeParse(response);
            if (!validationResults?.success) {
              logErrorToBugsnag(`DOP - API Validation Error - ${request.url}`, {
                response: validationResults.error,
                context: 'Base API Client',
              });
            }
          }
          return response;
        },
      ],
      beforeError: [
        error => {
          logErrorToBugsnag(`DOP - API Error- - ${error.request.url}`, {
            response: error,
            context: 'Base API Client',
          });
          return error;
        },
      ],
    },
  });
};

export default createApiClient;
