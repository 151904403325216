import AppConstants from '../AppConstants';
import env from '../env';

import VpProfile from '../types/responses/VPProfile';
import VenueMetadataResponseList, {
  venueMetadataResponseSchema,
} from '../types/responses/VenueMetadata';
import ViewPointProfileResponse, {
  vpProfileResponseSchema,
} from '../types/responses/VenueVpProfiles';
import createApiClient from './createApiClient';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.REACT_APP_ENVIRONMENT.DEV]:
    'https://dev.cmptconfig.cfadevelop.com/',
  [AppConstants.REACT_APP_ENVIRONMENT.TEST]:
    'https://test.cmptconfig.cfadevelop.com/',
  [AppConstants.REACT_APP_ENVIRONMENT.BETA]: 'https://cmptconfig.cfahome.com/',
  [AppConstants.REACT_APP_ENVIRONMENT.PROD]: 'https://cmptconfig.cfahome.com/',
};
const baseUrl = baseUrlMap[env.REACT_APP_ENVIRONMENT];
const apiClient = createApiClient(baseUrl);

const configurationService = {
  getVenueMetaData: (venueId: string) => {
    return apiClient
      .get(`venues/metadata/${venueId}`, {
        validationSchema: venueMetadataResponseSchema,
      })
      .json<VenueMetadataResponseList>();
  },
  getVpProfiles: (venueId: string) =>
    apiClient
      .get(`vpProfile/${venueId}`, {
        validationSchema: vpProfileResponseSchema,
      })
      .json<ViewPointProfileResponse>(),
  saveVpProfile: (profile: VpProfile, venueId: string) =>
    apiClient
      .post(`vpProfile/${venueId}`, {
        json: profile,
      })
      .json<VpProfile>(),
  deleteVpProfile: (vpProfileId: string, playId: string, venueId: string) =>
    apiClient
      .delete(`vpProfile/${venueId}`, {
        json: { playId, vpProfileId },
      })
      .json(),
} as const;

export default configurationService;
