import { useState, createContext, useMemo, useEffect, useContext } from 'react';
import configurationService from '../services/configurationService';
import {
  VenueMetadataContextType,
  VenueMetadata,
} from '../types/VenueMetadataType';
import { VenueNameContextType } from '../types/VenueNameContextType';
import { venueNameContext } from './VenueNameContextProvider';

type component = {
  children: React.ReactNode;
};

export const venueMetadataContext = createContext<VenueMetadataContextType>({
  venueMetadataSelection: {
    venueMetadata: {
      roles: [],
      origin: [],
      thirdParty: [],
      fulfillment: [],
      bumpProfiles: [],
      statuses: [],
      play: {
        id: '',
        name: '',
        vpProfiles: {},
      },
      menuItems: {},
    },
  },
  setvenueMetadataSelection: () => '',
});

const VenueMetadataContextProvider = ({ children }: component) => {
  const { venueSelection } = useContext(
    venueNameContext,
  ) as VenueNameContextType;
  const [venueMetadataSelection, setvenueMetadataSelection] =
    useState<VenueMetadata>({
      venueMetadata: {
        roles: [{ roles: '' }],
        origin: [{ origin: '' }],
        thirdParty: [{ thirdParty: '' }],
        fulfillment: [{ fulfillment: '' }],
        bumpProfiles: [{ bumpProfiles: '' }],
        statuses: [{ statuses: '' }],
        play: {
          id: '',
          name: '',
          vpProfiles: {},
        },
        menuItems: {},
      },
    });

  const getVenueMetaData = async () => {
    if (
      venueSelection.venue.venue_id !== '' &&
      !venueSelection.venue.venue_id.includes('undefined')
    ) {
      try {
        const venuemetadataresponse =
          await configurationService.getVenueMetaData(
            venueSelection.venue.venue_id,
          );
        const vpProfilesResponse = await configurationService.getVpProfiles(
          venueSelection.venue.venue_id,
        );

        const combinedResults = await Promise.all([
          venuemetadataresponse,
          vpProfilesResponse,
        ]);

        setvenueMetadataSelection({
          venueMetadata: {
            roles: [],
            origin: [],
            thirdParty: [],
            fulfillment: [],
            bumpProfiles: [],
            statuses: [],
            play: {
              id: '',
              name: '',
              vpProfiles: {},
            },
            menuItems: {
              Items: {
                deviceGroupId: '',
                name: '',
                items: [],
              },
            },
          },
        });
        setvenueMetadataSelection({
          venueMetadata: {
            roles: combinedResults[0].roles,
            origin: combinedResults[0].origin,
            thirdParty: combinedResults[0].thirdParty,
            fulfillment: combinedResults[0].fulfillment,
            bumpProfiles: [],
            statuses: combinedResults[0].statuses,
            menuItems: combinedResults[0].menuItems,
            play: {
              id:
                combinedResults[1]?.play === null ||
                combinedResults[1]?.play.id === undefined
                  ? ''
                  : combinedResults[1].play.id,
              name:
                combinedResults[1]?.play === null ||
                combinedResults[1]?.play.name === undefined
                  ? ''
                  : combinedResults[1].play.name,
              vpProfiles:
                combinedResults[1]?.play === null ||
                combinedResults[1]?.play.vpProfiles === null
                  ? {}
                  : combinedResults[1].play.vpProfiles,
            },
          },
        });
      } catch (error) {
        console.error('Error fetching venue metadata:', error);
      }
    }
  };

  useEffect(() => {
    getVenueMetaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueSelection]);

  const venueMetadataMemo = useMemo(
    () => ({ venueMetadataSelection, setvenueMetadataSelection }),
    [venueMetadataSelection],
  );

  return (
    <div>
      <venueMetadataContext.Provider value={venueMetadataMemo}>
        {children}
      </venueMetadataContext.Provider>
    </div>
  );
};

export default VenueMetadataContextProvider;
