import { z } from 'zod';

export const deviceGroupItemPrioritySchema = z.object({
  deviceGroupId: z.number(),
  deviceGroupName: z.string(),
  itemId: z.string(),
  priority: z.number(),
  priorityItemId: z.number().nullable(),
  stationNumber: z.number(),
});

type DeviceGroupItemPriority = z.infer<typeof deviceGroupItemPrioritySchema>;

export default DeviceGroupItemPriority;
