/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  AppRoutes: {
    appName: 'Menu Settings',
    autoSellCondiments: 'Auto-sell Condiments',
    customItemNames: 'Custom Item Names',
    customReceiptText: 'Custom Receipt Text',
    equipmentFailure: 'Equipment Failures',
    ingredientBasedLocks: 'Ingredient-based Locks',
    itemLock: 'Item Locks',
    limitedMenus: 'Limited Menus',
    masterMenu: 'Master Menu Selections',
    posHome: 'POS (Point of Sale)',
    priceOverride: 'Price Overrides',
    systemSelection: 'System Selection',
    systemStatus: 'System Status',
    taxExempt: 'Tax Exempt',
    venues: 'Venues and Distribution points',
    venuesDetail: 'Venue Detail',
  },
  AutoSellCondiments: {
    add: 'Add',
    addCond: 'Add Condiment',
    addCondTitle: 'Condiments for {{item}}',
    added: 'Added',
    addItem: 'Add Item',
    addItemTitle: 'Items with {{condiment}}',
    assigned: 'Assigned Condiments',
    assignedItems: 'Assigned Items',
    cancel: 'Cancel',
    conds: 'Condiments',
    header: 'Auto-sell Condiments',
    items: 'Items',
    remove: 'Remove',
    selCondiment: 'Select a condiment',
    selItem: 'Select an item',
    subHeader: 'Assign condiments to automatically include with menu items',
    updateCondiment: 'Update condiments',
    updateItem: 'Update items',
  },
  CustomItemNames: {
    cancelLabel: 'Cancel',
    clearAll: 'Clear all custom names shown ({{count}})',
    clearAllConfirmationBtn: 'Clear {{count}} item names',
    clearAllConfirmationLabel:
      'This will remove the customized text entered for these items.',
    clearAllConfirmationTitle:
      'Clear the customized names for the {{count}} items shown?',
    customizedSort: 'Customized name',
    customNameInputLabel: 'Customized name',
    defaultSort: 'Default item name',
    defaultTextLabel: 'Default item name',
    emptyItems: 'No items to display',
    header: 'Custom Item Names',
    item: 'Item',
    showOnlyCustomNamesLabel: 'Show only items with custom names',
    subHeader:
      'Customize menu item names on KPS monitors, guest receipts and virtual receipts',
  },
  CustomReceiptText: {
    additionalTextLabel: 'Additional Text (optional)',
    bottomOfReceiptLabel: 'Bottom of Receipt',
    cancelLabel: 'Cancel',
    clearAllConfirmationLabel:
      'Are you sure you want to clear all text on the top and bottom of this receipt?',
    clearAllLabel: 'Clear All',
    clearCustomTextLabel: 'Clear all custom text',
    closeLabel: 'Close',
    customizedTextLabel: 'Customized Text',
    defaultTextLabel: 'Default Text',
    lineNumberLabel: 'Line #',
    notCustomizableLabel: 'Not customizable',
    orderAndItemDetailsLabel: 'Order and item details',
    paymentDetailsLabel: 'Payment details',
    pricingDetailsLabel: 'Pricing details',
    printLabel: 'Print',
    promotionAndSurveyLabel: 'Promotion and survey',
    receiptPreviewLabel: 'Receipt Preview',
    seePreviewLabel: 'See Preview',
    subtitle: 'Customize text on the top and bottom of guest receipts',
    timestampLabel: 'Timestamp',
    title: 'Custom Receipt Text',
    topOfReceiptLabel: 'Top of Receipt',
  },
  EquipmentFailure: {
    boilWaterAdvisoriesLabel: 'Boil Water Advisories',
    cleaningSanitizingOnlyLabel: 'Cleaning/Sanitizing only',
    cleaningSanitizingOnlyNameLabel: 'Sanitization cleaner',
    equipmentSectionLabel: 'Equipment',
    griddleDownLabel: 'Griddle down',
    griddleDownNameLabel: 'Griddle',
    grillDownLabel: 'Grill down',
    grillDownNameLabel: 'Grill',
    header: 'Equipment Failure',
    iceDreamMachineDownLabel: 'Ice Dream machine down',
    iceDreamMachineDownNameLabel: 'Ice Dream machine',
    limitedFoodPrepLabel: 'Limited food prep',
    limitedFoodPrepNameLabel: 'Food prep',
    ovenDownLabel: 'Oven down',
    ovenDownNameLabel: 'Oven',
    subHeader: 'Turn on to lock all affected menu items',
    unavailableItemsLabel: 'The following items will not be available:',
    warningMessage:
      'Changes may take up to 30 minutes. Both in-restaurant and digital experiences will be updated. Catering will not receive updates.',
  },
  ErrorPage: {
    buttonText: 'Go to Home Screen',
    errorDescription: 'Please try again or contact the helpdesk',
    errorTitle: 'Something went wrong',
    maintenanceTitle: 'ControlPoint is currently down for maintenance',
  },
  Home: {
    cfaDeliveryOldTraysLabel: 'CFA Delivery – Catering Trays',
    cfaDeliveryOldTraysToggleLabel:
      'Make catering trays available for CFA Delivery orders',
    cfaDeliverySaharaFryLabel: 'CFA Delivery - Sahara Fries',
    cfaDeliverySaharaFryToggleLabel:
      'Make Sahara Fries available for CFA Delivery orders',
    failureLabel: '{{number}} failure',
    items: 'Items',
    lock: 'Locks',
    lockLabel: '{{number}} lock',
    pricing: 'Pricing',
    settingAccordionLabel: '{{name}}',
    title: 'Menu',
  },
  IngredientBasedLocks: {
    cateringSaharaFryConfirmModalSubtitle:
      'This {{lockStatus}} the sale of Sahara Fries with CFA Delivery',
    cateringSaharaFryConfirmModalTitle:
      'CFA Delivery Only (Sahara Fries - Operator-Led Delivery)',
    cateringTraysConfirmModalTitle:
      'CFA Delivery Only (Catering Trays - Operator-Led Delivery)',
    cateringTraysNameLabel: 'Catering trays',
    greenLeafLabel: 'Green leaf',
    greenLeafNameLabel: 'Green leaf lettuce',
    header: 'Ingredient-based Locks',
    petiteLettucesLabel: 'Petite red & green lettuces',
    petiteLettucesNameLabel: 'Petite lettuce',
    romaineLettuceLabel: 'Romaine lettuce',
    romaineLettuceNameLabel: 'Romaine lettuce',
    subHeader: 'Turn on to lock all affected menu items',
    unavailableItemsLabel: 'The following items will not be available:',
    warningMessage:
      'Changes may take up to 30 minutes. Both in-restaurant and digital experiences will be updated. Catering will not receive updates.',
  },
  ItemLock: {
    adminLockedStatusLabel: 'Admin Locked',
    cancel: 'Cancel',
    confirmLockAllBody:
      'This does not include items that are master menu or admin locked. When you {{lockType}} items {{lockOn}}, they cannot be sold at the POS or on digital channels.',
    confirmLockAllLock: '{{lockType}} Items {{lockOn}}',
    confirmLockAllTitle: '{{lockType}} {{itemCount}} Items {{lockOn}}',
    digitalLockButton: 'Lock Digital-only',
    digitalLockedStatusLabel: 'Digital-only Locked',
    fullLockButton: 'Fully Lock',
    header: 'Item locks',
    itemEmptyState: 'No items to display',
    itemLockButton: 'Lock',
    itemLockButtonMobile: 'Lock Item',
    itemLockDate: 'on {{date}} at {{time}} by {{name}}',
    itemUnlockButton: 'Unlock',
    itemUnlockButtonMobile: 'Unlock Item',
    lockAllItemsButton: 'Select {{count}} items',
    lockedStatusLabel: 'Locked',
    lockTypeOptionDigitalLock: 'Digital-only Locked',
    lockTypeOptionFullLock: 'Locked',
    lockTypeOptionMasterMenuLock: 'Master Menu Locked',
    lockTypeOptionSuperLock: 'Admin Locked',
    lockTypeOptionUnlocked: 'Unlocked',
    masterMenuLockedStatusLabel: 'Master Menu Locked',
    publishChanges: 'Publish Changes',
    publishChangesSubtitle:
      "When you publish changes, they will immediately take effect. Select the venue(s) you'd like to apply changes to:",
    publishNowButton: 'Publish Now',
    subHeader:
      'Lock items to make them unavailable to order in-store and/or digitally.',
    unlockButton: 'Unlock',
    unlockedStatusLabel: 'Unlocked',
  },
  LimitedMenus: {
    breakfastMenuLabel: 'Breakfast Menu',
    distributionContingencyMenuALabel:
      'Lunch/Dinner - Distribution Contingency Menu A',
    distributionContingencyMenuANameLabel: 'Lunch and dinner menu',
    distributionContingencyMenuBLabel:
      'Lunch/Dinner - Distribution Contingency Menu B',
    distributionContingencyMenuBNameLabel: 'Lunch and dinner menu',
    distributionContingencyMenuLabel:
      'Breakfast - Distribution Contingency Menu',
    distributionContingencyMenuNameLabel: 'Breakfast menu',
    header: 'Limited Menus',
    lunchDinnerMenuLabel: 'Lunch & Dinner Menu',
    subHeader: 'Activate a limited menu to lock the items shown',
    unavailableItemsLabel: 'The following items will not be available:',
    warningMessage:
      'Changes may take up to 30 minutes. Both in-restaurant and digital experiences will be updated. Catering will not receive updates.',
  },

  LocalItems: {
    backgroundColorLabel: 'Background color',
    buttonLabel: 'Button Details',
    cancel: 'Cancel',
    cancelLeave: 'Cancel, Stay on Page',
    cdliLabel: 'Use a Corporately Defined Item (CDLI)',
    confirmLeaveBody:
      'Are you sure you want to leave this page? You will lose changes that have not been published or added to the cart.',
    confirmLeaveTitle: 'Are you sure?',
    delete: 'Delete',
    deleteBtnLabel: 'Delete button',
    deleteLeave: 'Yes, Leave Page',
    deleteModalBody:
      'When you delete a button, it will permanently be removed from POS. You will need to recreate this item to sell it in the future. Are you sure you want to delete {{itemName}}?',
    deleteModalTitle: 'Delete this button?',
    deviceGroupLabel: 'Device group',
    hiddenMsg: 'Hidden on POS',
    hide: 'Hide',
    hideBtnLabel: '{{hideToggle}} button',
    hideModalBody:
      'When you hide a button, it will temporarily be removed from the POS. Are you sure you want to hide, {{itemName}}?',
    hideModalTitle: '{{hideToggle}} this button?',
    itemDetailsLabel: 'Item Details',
    itemsListCount: 'Items ({{count}})',
    kpsTextLabel: 'Receipt / KPS text',
    localItemDetailSubTitle:
      'Customize items and buttton properties and preview changes',
    localItemNameLabel: 'Item name',
    noItems: 'No items to display',
    priceLabel: 'Price',
    rename: 'Rename',
    renameModalHeader: 'Rename Item',
    selectBgColorLebel: 'Select background color',
    selectTextColorLabel: 'Select text color',
    show: 'Show',
    showModalBody:
      'When you show  a button, it will become visible on the POS, {{itemName}}?',
    subtitle:
      'Customize the sale of Local Items and Corporately Defined Local Items (CDLIs) that appear in-store on the POS',
    taxCategoryLabel: 'Tax category',
    textColorLabel: 'Text color',
    textLabel: 'Text',
    title: 'Local Items',
    typeLabel: 'Type',
  },
  MasterMenu: {
    createdByLabel: 'Modified by {{createdBy}}',
    header: 'Master Menu Selections',
    itemLocksButton: 'Go to Item Locks',
    jumpToLabel: 'Jump to:',
    revoteAvailableStatus: 'Master Menu selections are available',
    revoteCloseButton: 'Close selections',
    revoteClosedStatus:
      'Master Menu selection completed by {{createdBy}} on {{dateCreated}}. New Master Menu selections will take effect on {{effectiveDate}}.',
    revoteModifyButton: 'Modify selections',
    revoteModifyStatus:
      'Master Menu selection completed by {{createdBy}} on {{dateCreated}}. Voting ends at {{closeDate}}. New Master Menu selections will take effect on {{effectiveDate}}.',
    revotePromptModalCancel: 'Cancel',
    revotePromptModalConfirm: 'Select now',
    revotePromptModalSubtitle:
      'Licensed locations: Do not adjust catering options.',
    revotePromptModalTitle:
      'Master Menu selection is available from now until {{date}}',
    revoteSelectButton: 'Select now',
    revoteViewButton: 'View selections',
    selectionModalBackButton: 'Back',
    selectionModalCancelButton: 'Cancel',
    selectionModalCateringMessage:
      'Licensed locations: Do not adjust catering options.',
    selectionModalConfirmButton: 'Confirm',
    selectionModalNextButton: 'Next',
    selectionModalSubtitle: 'Select your desired menu option for each category',
    selectionModalTitle: 'Master Menu selections',
    subheader:
      'View current Master Menu selections and make changes during the revote period',
  },
  POS: {
    header: 'POS (Point of Sale)',
    home: 'Home',
    title: 'POS',
  },
  PriceOverride: {
    currentPrice: 'Current Price',
    emptyItems: 'No items to display',
    header: 'Price Overrides',
    item: 'Item',
    lockStatus: 'Lock Status',
    subHeader: 'Set custom prices for menu items',
  },
  QuickActions: {
    quickActionsConfirmModalCancelButton: 'Cancel',
    quickActionsConfirmModalConfirmButton: 'Confirm',
    quickActionsConfirmModalSubtitle:
      'This will {{lockStatus}} all menu items that require the use of the {{name}}.',
    quickActionsConfirmModalWarning:
      'Changes may take up to 30 minutes. Both in-restaurant and digital experiences will be updated. Catering will not receive updates.',
  },
  SaveButton: {
    save: 'Save',
  },
  TaxExempt: {
    activateAccountPromptModal: {
      subtitle: 'Are you sure you want to activate the account, {{name}}?',
      title: 'Activate this account?',
      warningMessage:
        'When an account is restored to active, you can use and edit it.',
    },
    activeFilter: 'Active ({{count}})',
    archiveAccountPromptModal: {
      subtitle: 'Are you sure you want to archive the account, {{name}}?',
      title: 'Archive this account?',
      warningMessage:
        'When an account is archived, you are unable to use or edit it until it is activated.',
    },
    archivedFilter: 'Archived ({{count}})',
    archivedOn: 'Archived On',
    breadcrumb: 'Tax Exempt Accounts',
    category: 'Category',
    deleteTaxAccountPromptModal: {
      subtitle:
        'Are you sure you want to permanently delete the account, {{name}}?',
      title: 'Permanently Delete an Account',
    },
    editTaxExemptModal: {
      errorMessage:
        'To continue, select at least one of the following options.',
      primaryButtonText: 'Update',
      secondaryButtonText: 'Cancel',
      subtitle:
        'Use the options to modify the tax exempt levels for this account',
    },
    emptyItems: 'No items to display',
    lastUpdated: 'Last Updated',
    lastUpdatedDate: '{{date}} at {{time}} by {{name}}',
    name: 'Name',
    popoverMenuActivateButton: 'Activate',
    popoverMenuArchiveButton: 'Archive',
    popoverMenuDeleteButton: 'Permanently Delete',
    popoverMenuEditAccountDetailsButton: 'Edit Account Details',
    popoverMenuEditTaxExemptLevelsButton: 'Edit Tax Exempt Levels',
    popoverMenuRenameButton: 'Rename',
    popoverMenuUpdateButton: 'Update',
    popoverMenuViewAccountDetailsButton: 'View Account Details',
    popoverMenuViewTaxExemptLevelsButton: 'View Tax Exempt Levels',
    promptModalCancelButton: '',
    promptModalCloseButton: 'Close',
    searchLabel: 'Search Accounts',
    searchPlaceholder: 'Enter account name',
    subTitle: 'Manage accounts to perform tax-free transactions',
    taxExemptModalAddButton: 'Add an Account',
    taxExemptModalCancelButton: 'Cancel',
    taxExemptModalSubmitButton: 'Add Account',
    title: 'Tax Exempt Accounts',
    viewTaxExemptPromptModal: {
      subtitle: 'View the tax exempt levels for this account',
    },
  },
  Venues: {
    activeVenuesLabel: 'Active Venues',
    addVenueButton: 'Add a Venue',
    archivedVenuesLabel: 'Archived Venues',
    createModalCancelButton: 'Cancel',
    createModalSubmitButton: 'Create Venue',
    detailsActivateButton: 'Activate this Venue',
    detailsArchiveButton: 'Archive this Venue',
    detailsDuplicateButton: 'Duplicate this Venue',
    detailsSaveButton: 'Save',
    duplicateVenueMessage:
      'When a venue is duplicated, it may take several seconds for new changes to load',
    duplicateVenueSubtitle:
      'Settings configured in the duplicated venue transfer to the new venue',
    emptyActiveVenuesLabel: 'No active venues',
    emptyArchivedVenuesLabel: 'No archived venues',
    idLabel: '({{storeNumber}}:{{venueId}})',
    popoverMenuActivateButton: 'Activate',
    popoverMenuArchiveButton: 'Archive',
    popoverMenuDeleteButton: 'Permanently Delete',
    popoverMenuDuplicateButton: 'Duplicate',
    popoverMenuEditButton: 'View/Edit Venue',
    popoverMenuResendButton: 'Resend Venue Data',
    promptModalCancelButton: 'Cancel',
    venueChangeButton: 'Change',
    venueChangeLabel: 'Venue: {{name}}',
    venueDetailActiveLabel: 'Status: Active',
    venueDetailArchivedLabel: 'Status: Archived',
    venueGatewayLabel: 'Service Gateway',
    venueGatewayLinkDescription:
      'You can make requests for new venue approval in',
    venueLockDate: '{{date}} at {{time}} by {{name}}',
    venueModalCancelButton: 'Cancel',
    venueModalHeader: 'Select a venue',
    venueModalSelectLabel: 'Venue',
    venueModalSubmitButton: 'Select',
    venueTabsActiveLabel: 'Active ({{count}})',
    venueTabsArchivedLabel: 'Archived ({{count}})',
  },
};
