import { z } from 'zod';
import GlobalSettingsResponse, {
  globalSettingsResponseSchema,
} from '../types/responses/GlobalSettingsResponse';
import KPSMonitor, { kpsMonitorSchema } from '../types/responses/KPSMonitor';
import ActivityLevel, {
  activityLevelSchema,
} from '../types/responses/ActivityLevel';
import TransactionColors, {
  transactionColorsSchema,
} from '../types/responses/TransactionColors';
import Color, { colorSchema } from '../types/responses/Color';
import DestinationColor, {
  destinationColorSchema,
} from '../types/responses/DestinationColor';
import Destination, { destinationSchema } from '../types/responses/Destination';
import AppConstants from '../AppConstants';
import DeviceGroup, { deviceGroupSchema } from '../types/responses/DeviceGroup';
import ItemColor, { itemColorSchema } from '../types/responses/ItemColor';
import Item, { itemSchema } from '../types/responses/Item';
import TransactionAlert, {
  transactionAlertSchema,
} from '../types/responses/TransactionAlerts';
import DestinationDisplayTime, {
  destinationDisplayTimeSchema,
} from '../types/responses/DestinationDisplayTime';
import BackupVersions, {
  backupVersionsSchema,
} from '../types/responses/BackupVersions';
import BumpTemplate, {
  bumpTemplateSchema,
} from '../types/responses/BumpTemplate';
import DeviceGroupPriority, {
  deviceGroupPrioritySchema,
} from '../types/responses/DeviceGroupPriority';
import Pos, { posSchema } from '../types/responses/Pos';
import DeviceGroupOverride, {
  deviceGroupOverrideSchema,
} from '../types/responses/DeviceGroupOverride';
import ControlOverrideDevice, {
  controlOverrideDeviceSchema,
} from '../types/responses/ControlOverrideDevice';
import env from '../env';
import View from '../types/responses/View';
import { KpsGroup } from '../utils/createBackupPayload';
import DeviceGroupItemPriority, {
  deviceGroupItemPrioritySchema,
} from '../types/responses/DeviceGroupItemPriority';
import SpcStore, { spcStoreSchema } from '../types/responses/SpcStore';
import createApiClient from './createApiClient';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.REACT_APP_ENVIRONMENT.DEV]: 'https://api.dev-spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TEST]: 'https://api.test-spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TRAIN]:
    'https://api.test-spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.BETA]: 'https://api.spc.cfahome.com',
  [AppConstants.REACT_APP_ENVIRONMENT.PROD]: 'https://api.spc.cfahome.com',
};

const baseUrl = baseUrlMap[env.REACT_APP_ENVIRONMENT];

const apiClient = createApiClient(baseUrl);

const spcApiService = {
  getSpcStore: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}`, {
        validationSchema: spcStoreSchema,
      })
      .json<SpcStore>(),
  getGlobalSettings: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/globalSettings`, {
        validationSchema: globalSettingsResponseSchema,
      })
      .json<GlobalSettingsResponse>(),
  saveGlobalSettings: (
    storeNumber: string,
    globalSettings: GlobalSettingsResponse,
  ) =>
    apiClient
      .put(`api/store/${storeNumber}/globalSettings`, {
        json: globalSettings,
      })
      .json(),
  getKPSMonitors: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/kpss`, {
        validationSchema: z.array(kpsMonitorSchema),
      })
      .json<KPSMonitor[]>(),
  saveKPSMonitor: (
    storeNumber: string,
    kpsMonitor: KPSMonitor,
    monitorId: number,
  ) =>
    apiClient
      .put(`api/store/${storeNumber}/kps/${monitorId}`, {
        json: kpsMonitor,
      })
      .json(),
  saveKPSMonitorView: (
    storeNumber: string,
    monitorView: View,
    monitorId: string,
    viewPosition: string,
  ) =>
    apiClient
      .put(`api/store/${storeNumber}/kps/${monitorId}/view/${viewPosition}`, {
        json: monitorView,
      })
      .json(),
  getActivityLevels: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/activitylevels`, {
        validationSchema: z.array(activityLevelSchema),
      })
      .json<ActivityLevel[]>(),
  saveActivityLevels: (storeNumber: string, activityLevels: ActivityLevel[]) =>
    apiClient
      .put(`api/store/${storeNumber}/activitylevels`, {
        json: activityLevels,
      })
      .json(),
  getTransactionColors: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/transactionColors`, {
        validationSchema: transactionColorsSchema,
      })
      .json<TransactionColors>(),
  saveTransactionColors: (
    storeNumber: string,
    transactionColors: TransactionColors,
  ) =>
    apiClient
      .put(`api/store/${storeNumber}/transactionColors`, {
        json: transactionColors,
      })
      .json(),
  getColors: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/colors`, {
        validationSchema: z.array(colorSchema),
      })
      .json<Color[]>(),
  getDestinationsColors: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/destinations/colors`, {
        validationSchema: z.array(destinationColorSchema),
      })
      .json<DestinationColor[]>(),
  saveDestinationsColors: (storeNumber: string, colors: DestinationColor[]) =>
    apiClient
      .post(`api/store/${storeNumber}/destinations/colors`, {
        json: colors,
      })
      .json(),
  getDestinations: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/destinations`, {
        validationSchema: z.array(destinationSchema),
      })
      .json<Destination[]>(),
  getTransactionAlerts: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/transactionAlerts`, {
        validationSchema: z.array(transactionAlertSchema),
      })
      .json<TransactionAlert[]>(),
  saveTransactionAlerts: (
    storeNumber: string,
    transactionAlerts: TransactionAlert,
  ) =>
    apiClient
      .put(`api/store/${storeNumber}/transactionAlert`, {
        json: transactionAlerts,
      })
      .json(),
  getDestinationDisplayTime: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/destinations/displayTimes`, {
        validationSchema: z.array(destinationDisplayTimeSchema),
      })
      .json<DestinationDisplayTime[]>(),
  saveDestinationDisplayTime: (storeNumber, destinationDisplayTimes) =>
    apiClient
      .post(`api/store/${storeNumber}/destinations/displayTimes`, {
        json: destinationDisplayTimes,
      })
      .json(),
  getDeviceGroups: (locationNumber: string) =>
    apiClient
      .get(`api/store/${locationNumber}/deviceGroups`, {
        validationSchema: z.array(deviceGroupSchema),
      })
      .json<DeviceGroup[]>(),
  getDeviceGroupOverrides: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/deviceGroupOverrides`, {
        validationSchema: z.array(deviceGroupOverrideSchema),
      })
      .json<DeviceGroupOverride[]>(),
  saveDeviceGroupOverrides: (
    storeNumber: string,
    deviceGroupOverrides: DeviceGroupOverride,
  ) =>
    apiClient
      .put(`api/store/${storeNumber}/deviceGroupOverrides`, {
        json: deviceGroupOverrides,
      })
      .json(),
  getItemColors: (locationNumber: string) =>
    apiClient
      .get(`api/store/${locationNumber}/itemColors`, {
        validationSchema: z.array(itemColorSchema),
      })
      .json<ItemColor[]>(),
  saveItemColors: (locationNumber: string, itemColors: ItemColor[]) =>
    apiClient
      .put(`api/store/${locationNumber}/itemColors`, { json: itemColors })
      .json(),
  resetItemColors: (locationNumber: string, itemColors: ItemColor[]) =>
    apiClient
      .put(`api/store/${locationNumber}/itemColors/resetColors`, {
        json: itemColors,
      })
      .json(),
  getItems: (locationNumber: string, search?: string) =>
    apiClient
      .get(
        `api/store/${locationNumber}/items${search ? `?search=${search}` : ''}`,
        {
          validationSchema: z.array(itemSchema),
        },
      )
      .json<Item[]>(),
  getItemsByDeviceGroupId: (locationNumber: string, deviceGroupId: string) =>
    apiClient
      .get(`api/store/${locationNumber}/items/${deviceGroupId}`, {
        validationSchema: z.array(itemSchema),
      })
      .json<Item[]>(),
  getKPSMonitor: (storeNumber: string, monitorId: string) =>
    apiClient
      .get(`api/store/${storeNumber}/kps/${monitorId}`, {
        validationSchema: kpsMonitorSchema,
      })
      .json<KPSMonitor>(),
  getBumpTemplates: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/bumpTemplates`, {
        validationSchema: z.array(bumpTemplateSchema),
      })
      .json<BumpTemplate[]>(),
  getDeviceGroupPriority: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/deviceGroupPriority`, {
        validationSchema: z.array(deviceGroupPrioritySchema),
      })
      .json<DeviceGroupPriority[]>(),
  getDeviceGroupItemPriority: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/deviceGroupItemPriorities`, {
        validationSchema: z.array(deviceGroupItemPrioritySchema),
      })
      .json<DeviceGroupItemPriority[]>(),
  saveDeviceGroupPriority: (
    storeNumber: string,
    deviceGroupPriorities: DeviceGroupPriority[],
  ) =>
    apiClient
      .post(`api/store/${storeNumber}/deviceGroupPriority`, {
        json: deviceGroupPriorities,
      })
      .json(),
  saveDeviceGroupItemPriority: (
    storeNumber: string,
    deviceGroupItemPriorities: DeviceGroupItemPriority[],
  ) =>
    apiClient
      .post(`api/store/${storeNumber}/deviceGroupItemPriorities`, {
        json: deviceGroupItemPriorities,
      })
      .json(),
  getAllPos: (storeNumber: string) =>
    apiClient
      .get(`api/store/${storeNumber}/poss`, {
        validationSchema: z.array(posSchema),
      })
      .json<Pos[]>(),
  getBackupVersions: (locationNumber: string) =>
    apiClient
      .get(`api/store/${locationNumber}/versions`, {
        validationSchema: z.array(backupVersionsSchema),
      })
      .json<BackupVersions[]>(),
  saveSendBackupVersion: (locationNumber: string, selected: BackupVersions) =>
    apiClient
      .post(
        `api/store/${locationNumber}/command/syncchanges/${selected?.version}`,
      )
      .json(),
  saveScheduledBackupVersion: (
    locationNumber,
    selected: BackupVersions,
    date: string | undefined,
  ) =>
    apiClient
      .post(
        `api/store/${locationNumber}/command/syncchanges/${selected?.version}`,
        { json: date },
      )
      .json(),
  restoreBackup: (locationNumber: string, selected: BackupVersions) =>
    apiClient
      .post(`api/store/${locationNumber}/restoreVersion`, {
        json: selected,
      })
      .json(),
  sendBackupChangeNow: (locationNumber: string, backupData: BackupVersions) =>
    apiClient
      .post(`api/store/${locationNumber}/command/syncchanges`, {
        json: {
          backupName: backupData.name,
          backupChangeEvents: backupData.backupChangeEvents,
        },
      })
      .json(),
  syncChanges: (
    storeNumber: string,
    kpsGroups: KpsGroup[],
    backupName: string,
    date: string | null,
  ) =>
    apiClient
      .post(`api/store/${storeNumber}/command/syncchanges`, {
        json: {
          backupChangeEvents: JSON.stringify(kpsGroups),
          backupName,
          sendDate: date,
        },
      })
      .json(),
  deleteChanges: (locationNumber: string) =>
    apiClient.get(`api/store/${locationNumber}/revertToChangeVersion`).json(),
  copyItemRoutes: (
    locationNumber: string,
    retailModifiedItemId: number,
    retailModifiedItemIds: number[],
  ) =>
    apiClient
      .put(
        `api/store/${locationNumber}/rmis/copyRouteFrom/${retailModifiedItemId}`,
        {
          json: retailModifiedItemIds,
        },
      )
      .json(),
  getControlOverrideDevices: (locationNumber: string) =>
    apiClient
      .get(`api/store/${locationNumber}/controlOverrideDevices`, {
        validationSchema: z.array(controlOverrideDeviceSchema),
      })
      .json<ControlOverrideDevice[]>(),
  saveControlOverrideDevices: (
    storeNumber: string,
    controlOverrideDevices: ControlOverrideDevice[],
  ) =>
    apiClient
      .put(`api/store/${storeNumber}/controlOverrideDevices`, {
        json: controlOverrideDevices,
      })
      .json(),
} as const;

export default spcApiService;
