enum NODE_ENV {
  DEVELOPMENT = 'development',
  TEST = 'test',
  PRODUCTION = 'production',
}

enum REACT_APP_ENVIRONMENT {
  DEV = 'dev',
  TEST = 'test',
  TRAIN = 'train',
  BETA = 'beta',
  PROD = 'prod',
}

const AppConstants = {
  NODE_ENV,
  REACT_APP_ENVIRONMENT,
};

export default AppConstants;
