import { lazy, Suspense, useEffect, useMemo } from 'react';
import './i18n';
import {
  appLoadingStatuses,
  AppNames,
  cartDataPointsGroups,
  deleteCartDataPoints,
  getCart,
  globalRoutes,
  publishCartDataPoints,
  userPermissions,
} from '@cfacorp/ctrl-platform-ui-core-utils';
import RouteInfo from '@cfacorp/ctrl-platform-ui-core-utils/dist/types/RouteInfo';
import { useSelectedStoreNumber } from '@cfacorp/ctrl-platform-shared-react-components';
import { useQueryClient } from '@tanstack/react-query';
import useHasViewPointPerms from './hooks/useHasViewPointPerms';
import exampleService from './services/exampleService';
import { appDataPointGroups } from './enums/AppDataPoints';
import useAppTranslation from './hooks/useAppTranslation';
import RouteConstants from './routes/RouteConstants';
import VenueContextProvider from './Context/VenueNameContextProvider';
import MultiStepContextProvider from './Context/MultiStepContextProvider';
import VenueMetadataContextProvider from './Context/VenueMetadataContextProvider';

const AppRouter = lazy(() => import('./routes/AppRouter'));

export default function Root() {
  const { t } = useAppTranslation();
  const selectedStoreNumber = useSelectedStoreNumber();
  const queryClient = useQueryClient();
  const routes: RouteInfo[] = useMemo(
    () => [
      {
        route: RouteConstants.BASE_NAME,
        displayName: t('AppRoutes.baseName'),
        keywords: ['ViewPoint'],
      },
      {
        route: RouteConstants.CREATE_PROFILE,
        displayName: t('AppRoutes.createProfile'),
        keywords: ['CreateProfile'],
      },
    ],
    [t],
  );
  const { hasViewPointPerms, permsLoaded } = useHasViewPointPerms();

  useEffect(() => {
    cartDataPointsGroups.next({
      ...cartDataPointsGroups.value,
      [AppNames.VIEWPOINT]: appDataPointGroups,
    });
  }, []);

  useEffect(() => {
    if (permsLoaded) {
      userPermissions.next({
        ...userPermissions.value,
        hasViewPointAccess: hasViewPointPerms,
      });
      appLoadingStatuses.next({
        ...appLoadingStatuses.value,
        viewPointLoaded: true,
      });
      globalRoutes.next({
        routes: [...globalRoutes.value.routes, ...routes],
      });
    }
  }, [hasViewPointPerms, permsLoaded, routes]);

  useEffect(() => {
    publishCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.appName === AppNames.VIEWPOINT &&
        selectedStoreNumber.length
      ) {
        exampleService.publishCart(selectedStoreNumber).then(() => {
          getCart(selectedStoreNumber);
          queryClient.clear();
        });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  useEffect(() => {
    deleteCartDataPoints.subscribe(cartGroups => {
      if (
        cartGroups.appName === AppNames.VIEWPOINT &&
        selectedStoreNumber.length
      ) {
        exampleService.deleteCart(selectedStoreNumber).then(() => {
          getCart(selectedStoreNumber);
          queryClient.clear();
        });
      }
    });
  }, [queryClient, selectedStoreNumber]);

  return (
    <Suspense fallback={null}>
      <VenueContextProvider>
        <VenueMetadataContextProvider>
          <MultiStepContextProvider>
            <AppRouter />
          </MultiStepContextProvider>
        </VenueMetadataContextProvider>
      </VenueContextProvider>
    </Suspense>
  );
}
