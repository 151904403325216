import { LoadingIndicator } from 'cfa-react-components';
import { FC, lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import {
  NotFoundPage,
  Overlay,
} from '@cfacorp/ctrl-platform-shared-react-components';
import RouteConstants from '../RouteConstants';
import RouterErrorBoundary from '../RouterErrorBoundary';
import CaresRoot from './CaresRoot';
const Cares = lazy(() => import('../../containers/Cares'));

const router = createBrowserRouter(
  [
    {
      path: RouteConstants.HOME,
      element: <CaresRoot />,
      errorElement: <RouterErrorBoundary />,
      children: [
        {
          path: RouteConstants.HOME,
          element: <Cares />,
        },
      ],
    },
    { path: '*', element: <NotFoundPage /> },
  ],
  { basename: RouteConstants.CARES_BASE_NAME },
);

const CaresRouter: FC = () => {
  return (
    <RouterProvider
      router={router}
      fallbackElement={
        <Overlay isOpen>
          <LoadingIndicator variant="page" />
        </Overlay>
      }
    />
  );
};

export default CaresRouter;
