import { z } from 'zod';
import KpsChange, { kpsChangeSchema } from '../types/KpsChange';
import AppConstants from '../AppConstants';
import env from '../env';
import createApiClient from './createApiClient';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.APP_ENVIRONMENT.DEV]: 'https://api.dev-spc.cfahome.com',
  [AppConstants.APP_ENVIRONMENT.TEST]: 'https://api.test-spc.cfahome.com',
  [AppConstants.APP_ENVIRONMENT.TRAIN]: 'https://api.test-spc.cfahome.com',
  [AppConstants.APP_ENVIRONMENT.BETA]: 'https://api.spc.cfahome.com',
  [AppConstants.APP_ENVIRONMENT.PROD]: 'https://api.spc.cfahome.com',
};

const baseUrl = baseUrlMap[env.APP_ENVIRONMENT];

const apiClient = createApiClient(baseUrl);

const spcService = {
  getCart: storeNumber =>
    apiClient
      .get(`api/store/${storeNumber}/changes`, {
        validationSchema: z.array(kpsChangeSchema),
      })
      .json<KpsChange[]>(),
} as const;

export default spcService;
