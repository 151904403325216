import { z } from 'zod';

export const PlaySchema = z.object({
  id: z.string(),
  name: z.string(),
  vpProfiles: z.record(
    z.object({
      name: z.string(),
      role: z.string(),
      origin: z.array(z.string()),
      originThirdParty: z.array(z.string()),
      fulfillment: z.array(z.string()),
      bumpProfiles: z.array(z.string()),
      bumpStatuses: z.array(z.string()),
      bumpOwnProfile: z.boolean(),
      menuItems: z.object({
        fullSelectedDeviceGroups: z.array(z.string()),
        partialSelectedDeviceGroups: z.array(z.string()),
        partialSelectedItems: z.record(z.array(z.string())),
      }),
    }),
  ),
});

export const vpProfileResponseSchema = z.object({
  play: PlaySchema,
});

type ViewPointProfileResponse = z.infer<typeof vpProfileResponseSchema>;
export default ViewPointProfileResponse;
