import { z } from 'zod';

export const rolesSchema = z.object({
  roles: z.string(),
});

export const originsSchema = z.object({
  origin: z.string(),
});

export const originsThirdSchema = z.object({
  thirdParty: z.string(),
});

export const fulfillmentsSchema = z.object({
  fulfillment: z.string(),
});

export const bumpProfilesSchema = z.object({
  bumpProfiles: z.string(),
});

export const statusesSchema = z.object({
  statuses: z.string(),
});
export const bumpsSchema = z.object({
  bumpProfiles: z.array(bumpProfilesSchema),
  statuses: z.array(statusesSchema),
});
export type RoleElements = z.infer<typeof rolesSchema>;
export type ThirdPartyElements = z.infer<typeof originsSchema>;

export type OriginsElements = z.infer<typeof originsSchema>;

export type FulfillmentsElements = z.infer<typeof fulfillmentsSchema>;
export type ProfileElements = z.infer<typeof bumpProfilesSchema>;

export type StatusElements = z.infer<typeof statusesSchema>;

export type BumpsElements = z.infer<typeof bumpsSchema>;

const MenuItemSchema = z.object({
  deviceGroupId: z.string(),
  name: z.string(),
  items: z.array(z.string()),
});

const MenuItemsSchema = z.record(MenuItemSchema);

export const PlaySchema = z.object({
  id: z.string(),
  name: z.string(),
  vpProfiles: z.record(
    z.object({
      name: z.string(),
      role: z.string(),
      origin: z.array(z.string()),
      originThirdParty: z.array(z.string()),
      fulfillment: z.array(z.string()),
      bumpProfiles: z.array(z.string()),
      bumpStatuses: z.array(z.string()),
      bumpOwnProfile: z.boolean(),
      menuItems: z.object({
        fullSelectedDeviceGroups: z.array(z.string()),
        partialSelectedDeviceGroups: z.array(z.string()),
        partialSelectedItems: z.record(z.array(z.string())),
      }),
    }),
  ),
});

export const venueMetadataResponseSchema = z.object({
  roles: z.array(rolesSchema),
  origin: z.array(originsSchema),
  thirdParty: z.array(originsThirdSchema),
  fulfillment: z.array(fulfillmentsSchema),
  bumpProfiles: z.array(bumpProfilesSchema),
  statuses: z.array(statusesSchema),
  menuItems: MenuItemsSchema,
  play: PlaySchema,
});

type VenueMetadataResponseList = z.infer<typeof venueMetadataResponseSchema>;

export default VenueMetadataResponseList;
