import { z } from 'zod';

export const globalSettingsResponseSchema = z.object({
  globalSettingsId: z.number(),
  start_timer_from_state: z.number(),
  transaction_bump_on_status: z.number(),
  check_auto_bump_from_state: z.boolean(),
  auto_bump_expire_time: z.number(),
  item_label_item_font: z.number(),
  item_label_subitem_font: z.number(),
  menu_item_large_font: z.boolean(),
});

type GlobalSettingsResponse = z.infer<typeof globalSettingsResponseSchema>;

export default GlobalSettingsResponse;
