import { z } from 'zod';

const envSchema = z.object({
  REACT_APP_ENVIRONMENT: z.enum(['dev', 'test', 'train', 'beta', 'prod']),
  REACT_APP_MAP_KEY: z.string(),
});

const env = envSchema.parse(process.env);

export default env;
