import { Coordinates } from '@bufteam/cfacorp_delivery.bufbuild_es/cfa/delivery/core/v1/coordinates_pb';
import { useCallback, useEffect, useState } from 'react';
import { useShowAdminActions } from '@cfacorp/ctrl-platform-shared-react-components';
import { getLatLng, getOuterBounds } from '../containers/DeliveryArea/utils';
import { colors } from '../theme';
import { useGetAreaQueries } from './useGetAreaQueries';
import { useGetStoreNumber } from './useGetStoreNumber';

export const useMaxArea = ({
  map,
}: {
  map: google.maps.Map | null;
}): {
  setIsMaxAreaEdited: (b: boolean) => void;
  maxAreaCoordinates?: Coordinates[];
  isMaxAreaEdited: boolean;
  resetMaxArea: () => void;
} => {
  const { queryMax } = useGetAreaQueries();
  const storeNumber = useGetStoreNumber();
  const isAdmin = useShowAdminActions();
  const [maxPolygon, setMaxPolygon] = useState<google.maps.Polygon | null>(
    null,
  );
  const [isMaxAreaEdited, setIsMaxAreaEdited] = useState<boolean>(false);
  const [isListening, setIsListening] = useState<boolean>(false);
  const [maxAreaCoordinates, setMaxAreaCoordinates] = useState<
    Coordinates[] | undefined
  >();

  useEffect(() => {
    if (!maxAreaCoordinates?.length && storeNumber?.length) {
      queryMax().then((c: Coordinates[]) => {
        setMaxAreaCoordinates(c);
        // trigger initializeMaxPolygon
        maxPolygon?.setPath([]);
      });
    }
  }, [maxAreaCoordinates, storeNumber, queryMax, maxPolygon]);

  const initializeMaxPolygon = useCallback(() => {
    if (
      !maxPolygon?.getPath()?.getArray()?.length &&
      !!maxAreaCoordinates?.length
    ) {
      maxPolygon?.setMap(null);
      const polygon = new google.maps.Polygon({
        paths: [getLatLng(maxAreaCoordinates), getOuterBounds()],
        fillColor: colors.gray7,
        editable: isAdmin,
        fillOpacity: 0.5,
        strokeWeight: 0,
      });
      setMaxPolygon(polygon);
      polygon.setMap(map);
    }
  }, [isAdmin, map, maxAreaCoordinates, maxPolygon]);

  useEffect(() => initializeMaxPolygon(), [initializeMaxPolygon]);

  const updateCoordinates = useCallback(
    (p: google.maps.Polygon | null) =>
      p &&
      setMaxAreaCoordinates(
        p
          .getPath()
          .getArray()
          .map(
            (ll: google.maps.LatLng) =>
              new Coordinates({ longitude: ll.lng(), latitude: ll.lat() }),
          ),
      ),
    [],
  );

  const addPolygonListeners = useCallback(() => {
    if (maxPolygon?.getPath()?.getArray()?.length && !isListening && isAdmin) {
      setIsListening(true);
      const path = maxPolygon.getPath();
      google.maps.event.addListener(path, 'insert_at', () => {
        updateCoordinates(maxPolygon);
        setIsMaxAreaEdited(true);
      });
      google.maps.event.addListener(path, 'set_at', () => {
        updateCoordinates(maxPolygon);
        setIsMaxAreaEdited(true);
      });
    }
  }, [maxPolygon, isListening, updateCoordinates, isAdmin]);

  useEffect(() => addPolygonListeners(), [addPolygonListeners]);

  // if user is an admin, then set as editable
  // not editable if user not an admin
  useEffect(() => maxPolygon?.setEditable(isAdmin), [isAdmin, maxPolygon]);

  const resetMaxArea = useCallback(() => {
    setMaxAreaCoordinates([]);
    setIsMaxAreaEdited(false);
    maxPolygon?.setPath([]);
    setIsListening(false);
  }, [maxPolygon]);

  return {
    setIsMaxAreaEdited,
    maxAreaCoordinates,
    isMaxAreaEdited,
    resetMaxArea,
  };
};
