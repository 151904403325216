import MapsKey, { mapsKeyResponseSchema } from '../types/responses/MapsKey';
import LocationCoordinates, {
  Coordinates,
  locationCoordinatesResponseSchema,
} from '../types/responses/LocationCoordinates';
import AppConstants from '../AppConstants';
import env from '../env';
import createApiClient from './createApiClient';

const baseUrlMap: { [key: string]: string } = {
  [AppConstants.REACT_APP_ENVIRONMENT.DEV]:
    'https://delivery-api.waf.us-east-1.dxe.qa.crndev.chick-fil-a.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TEST]:
    'https://delivery-api.waf.us-east-1.dxe.qa.crndev.chick-fil-a.com',
  [AppConstants.REACT_APP_ENVIRONMENT.TRAIN]:
    'https://delivery-api.waf.us-east-1.dxe.qa.crndev.chick-fil-a.com',
  [AppConstants.REACT_APP_ENVIRONMENT.BETA]:
    'https://delivery-api.waf.us-east-1.dxe.my.chick-fil-a.com',
  [AppConstants.REACT_APP_ENVIRONMENT.PROD]:
    'https://delivery-api.waf.us-east-1.dxe.my.chick-fil-a.com',
};

const baseUrl = baseUrlMap[env.REACT_APP_ENVIRONMENT];

const apiClient = createApiClient(baseUrl);

const deliveryService = {
  getGoogleMapsApiKey: () =>
    apiClient
      .get(`onboard/mapsKey`, {
        validationSchema: mapsKeyResponseSchema,
      })
      .json<MapsKey>(),
  getDeliveryCoordinates: (locationNumber: string) =>
    apiClient
      .get(`onboard/${locationNumber}`, {
        validationSchema: locationCoordinatesResponseSchema,
      })
      .json<LocationCoordinates>(),
  addUpdatedCoordinatesToCart: ({
    coordinates,
    locationNumber,
  }: {
    coordinates: Coordinates[];
    locationNumber: string;
  }) =>
    apiClient
      .put(`onboard/cart/${locationNumber}`, { json: { coordinates } })
      .json(),
  publishUpdatedCoordinates: ({ coordinates, locationNumber }) =>
    apiClient
      .put(`onboard/${locationNumber}`, { json: { coordinates } })
      .json(),
  publishCart: (locationNumber: string) =>
    apiClient
      .put(`onboard/cart/publish/${locationNumber}`, { json: [] })
      .json(),
  deleteCart: (locationNumber: string) =>
    apiClient.delete(`onboard/cart/${locationNumber}`).json(),
} as const;

export default deliveryService;
