enum FeatureFlags {
  KPS_HARDWARE = 'KPS_HARDWARE',
  MENU_SYSTEM_SELECTION = 'MENU_SYSTEM_SELECTION',
  DRIVE_THRU_EXPRESS = 'DRIVE_THRU_EXPRESS',
  PROFILES = 'PROFILES',
  DEVICE_GROUP_PRIORITY = 'DEVICE_GROUP_PRIORITY',
  PRINT_CUSTOMER_RECEIPT = 'PRINT_CUSTOMER_RECEIPT',
  DELIVERY_AREA = 'DELIVERY_AREA',
  MMS_LICENSEE = 'MMS_LICENSEE',
  AUTO_CHECK_IN = 'AUTO_CHECK_IN',
  DEVICE_GROUP_ITEM_PRIORITY = 'DEVICE_GROUP_ITEM_PRIORITY',
  BEVERAGE_RECEIPT = 'BEVERAGE_RECEIPT',
  CFA_DELIVERY_ON_OFF = 'CFA_DELIVERY_ON_OFF',
  THIRD_P_DELIVERY = '3P_DELIVERY',
  SUPER_LOCKS = 'SUPER_LOCKS',
  CFA_DELIVERY_HOURS = 'CFA_DELIVERY_HOURS',
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  BEVERAGE_HIGHLIGHTING = 'BEVERAGE_HIGHLIGHTING_(KPS)',
  VENUES = 'VENUES',
  INGREDIENT_BASED_LOCKS = 'INGREDIENT-BASED_LOCKS',
  LIMITED_MENUS = 'LIMITED_MENUS',
  CUSTOM_RECEIPT_TEXT = 'CUSTOM_RECEIPT_TEXT',
  BEVERAGE_AND_TREAT_LABELS = 'BEVERAGE_AND_TREAT_LABELS',
  SMALL_PRINTER_PAPER = '58MM_RECEIPT_PAPER',
  VIEWPOINT_PROFILES = 'VIEWPOINT_PROFILES',
  MAXIMUM_CURBSIDE_ORDERS = 'MAXIMUM_CURBSIDE_ORDERS',
  SCHEDULED_CHANGES = 'SCHEDULED_CHANGES',
  LOCAL_ITEMS = 'LOCAL_ITEMS',
  THIRD_P_STATUS_PAGE = '3P_STATUS_PAGE',
  VIEWPOINT = 'VIEWPOINT',
  SAHARA_FRY = 'SAHARA_FRY',
  VIEWPOINT_MENU_SORTING = 'VIEWPOINT_MENU_SORTING',
  TAX_EXEMPT_ACCOUNTS = 'TAX_EXEMPT_ACCOUNTS',
  VIEWPOINT_SERVICE_RECEIPT = 'VIEWPOINT_SERVICE_RECEIPT',
}

export default FeatureFlags;
